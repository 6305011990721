import { Roles } from "@/constants/roles"
import type { Space } from "@/types"

export function getUserType(roles: Space["roles"]) {
  if (roles.length === 0) return "admin" as const
  switch (roles[0]) {
    case Roles["role:edu-admin"]:
    case Roles["role:pro-admin"]:
      return "admin" as const
    case Roles["role:edu-teacher"]:
      return "teacher" as const
    case Roles["role:edu-student"]:
      return "student" as const
    case Roles["role:pro-editor"]:
      return "editor" as const
    default:
      return "admin" as const
  }
}
