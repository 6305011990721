import type { Space } from "@/types"

export const Roles = {
  "role:edu-admin": "role:edu-admin",
  "role:edu-teacher": "role:edu-teacher",
  "role:edu-student": "role:edu-student",
  "role:pro-admin": "role:pro-admin",
  "role:pro-editor": "role:pro-editor"
} as const

export const ADMIN_ROLES = [Roles["role:edu-admin"], Roles["role:pro-admin"]] as ReadonlyArray<string>
export const EDUCATOR_ROLES = [...ADMIN_ROLES, Roles["role:edu-teacher"]] as ReadonlyArray<string>
export const ALL_ROLES = [...EDUCATOR_ROLES, Roles["role:edu-student"]] as ReadonlyArray<string>

export const RolesMap: Record<
  Space["space"],
  Array<{
    value: keyof typeof Roles
    label: string
  }>
> = {
  edu: [
    { value: Roles["role:edu-admin"], label: "Admin" },
    { value: Roles["role:edu-teacher"], label: "Teacher" },
    { value: Roles["role:edu-student"], label: "Student" }
  ],
  pro: [
    { value: Roles["role:pro-admin"], label: "Admin" },
    { value: Roles["role:pro-editor"], label: "Editor" }
  ],
  private: []
}
