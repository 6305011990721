import { unwrapResponse } from "@/api/utils/NetworkError"
import type { Kit, Space } from "@/types"

import { authFetch } from "./utils"

// TODO: Migrate to client

export async function checkCode(code: string) {
  try {
    const res = await authFetch(`${import.meta.env.VITE_API2_URL}/education/v1/studentkits/codes/${code.toUpperCase()}`)
    const data = await res.json()
    return data
  } catch (error) {
    return error
  }
}

export async function registerKit(code: string, parentID?: string, space_id?: Space["id"]) {
  const normalizedCode = code.toUpperCase()

  if (parentID) {
    const res = await authFetch(`${import.meta.env.VITE_API2_URL}/education/v1/kits/${parentID}/extensions`, {
      method: "POST",
      body: space_id
        ? JSON.stringify({ code: normalizedCode, organization_id: space_id })
        : JSON.stringify({ code: normalizedCode })
    })
    return unwrapResponse<Kit>(res)
  }

  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/education/v1/kits/`, {
    method: "POST",
    body: space_id
      ? JSON.stringify({ code: normalizedCode, organization_id: space_id })
      : JSON.stringify({ code: normalizedCode })
  })
  return unwrapResponse<Kit>(res)
}

export async function getKits() {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/education/v2/kits`)
  const list = await unwrapResponse<Kit[]>(res)
  return list
}
