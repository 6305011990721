import { useAuth } from "@bcmi-labs/art-auth"
import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { query } from "@/api/query"
import { MY_SPACE, SPACE_PAID_PLANS } from "@/constants/spaces"
import { useSpace } from "@/lib/hooks/useSpace"
import { getUserType } from "@/lib/utils/space"
import type { Space, Subscriptions } from "@/types"

const LORA_DEVICE_IDENTIFIERS = [
  "cloud_devices_arduino_mkrwan1310",
  "cloud_devices_arduino_mkrwan1300",
  "cloud_devices_nonarduino_lora-device",
  "cloud_devices_linux_lora-gateway"
]

/** A hook that returns the user recap from the query store as a stateful value. */
export function useRecap() {
  const { user } = useAuth()
  const { space } = useSpace()

  // Check if we're on an expired pro plan
  const isProExpired = space.space === "pro" && !space.plan_id

  /**
   * Get recap for the current space
   */
  const spaceRecap = useQuery({
    ...query.space.subscriptions(space.id),
    staleTime: Infinity
  })

  /**
   * Get recap for the current user
   */
  const userRecap = useQuery({
    ...query.space.subscriptions(MY_SPACE.id),
    staleTime: Infinity
  })

  /**
   * Check if the current space has a paid plan.
   */
  const isPaidPlan = spaceRecap.data?.plans.some(item => Object.keys(SPACE_PAID_PLANS).includes(item)) || false

  // Get limits
  const things = spaceRecap.data?.usage.cloud_things || 0
  const api = spaceRecap.data?.usage.cloud_api_clients || 0

  // These booleans will be false if undefined or 0. We're expecting 1 when the user has the integration.

  /**
   * Get integrations for the current space
   */
  const integrations = {
    hasLoraDevice: spaceRecap.data
      ? Object.keys(spaceRecap.data.usage)
          .filter(key => LORA_DEVICE_IDENTIFIERS.includes(key))
          .reduce((acc, key) => (spaceRecap.data.usage[key] || 0) + acc, 0) > 0
      : false,
    hasFoundries: Boolean(spaceRecap.data?.limits.foundries),
    hasEdgeImpulse: Boolean(spaceRecap.data?.limits.edge_impulse)
  }

  /** Usage recap */
  const usage = useMemo(
    () => ({
      things: {
        current: things,
        limit: spaceRecap.data?.limits.cloud_things || 0
      },
      sketches: {
        current: spaceRecap.data?.usage.create_disk_sketches || 0,
        limit:
          // If disk limit is over 1Gb, it means it's unlimited
          (spaceRecap.data?.limits.create_sketches_disk || 0) > 1048576000
            ? Infinity
            : spaceRecap.data?.limits.create_sketches_disk || 0
      },
      compilation: {
        current: spaceRecap.data?.usage.create_compilation_day || 0,
        limit: spaceRecap.data?.limits.create_compilation_day || 0
      },
      api: {
        current: api,
        limit: userRecap.data?.limits.cloud_api ? 1000 : 0
      }
    }),
    [api, spaceRecap.data, things, userRecap.data?.limits.cloud_api]
  )

  // Quotas should always be positive (meaning we have less than the limit).
  const quotadiff = useMemo(
    () => Object.fromEntries(Object.entries(usage).map(([key, value]) => [key, value.limit - value.current])),
    [usage]
  ) as Record<keyof typeof usage, number> // ? See if we can avoid casting here

  // Evaluate quota state for each limit.
  const isAtQuota = Object.fromEntries(
    Object.entries(quotadiff).map(([key, value]) => [key, isProExpired || value <= 0])
  ) as Record<keyof typeof quotadiff, boolean>

  const isOverQuota = Object.fromEntries(
    Object.entries(quotadiff).map(([key, value]) => [key, isProExpired || value < 0])
  ) as Record<keyof typeof quotadiff, boolean>

  // Shorthands to check if any of the limits is reached
  const isOverReached = Object.values(isOverQuota).some(Boolean) // ! This indicates generic overquota (usually expired plan)

  // Features that are enabled for the current space
  const features = useMemo(
    () => ({
      triggers: Boolean(spaceRecap.data?.limits.cloud_triggers)
    }),
    [spaceRecap.data?.limits.cloud_triggers]
  )

  const userType = getUserType(space.roles)

  const upgradeLink = getPlanUpgradeLink(space, user["http://arduino.cc/is_minor"], spaceRecap.data?.plans || [])

  /**
   * Returns how many member seats are still available for the current space. If unlimited, returns 9999
   */
  const availableMemberSeats = useMemo(() => {
    const { plan_id, plan_seats, members, space: spaceType } = space
    // Pro spaces have no member limit
    if (spaceType === "pro") return 9999
    // Free edu spaces have no member limit
    return !plan_id ? 9999 : (plan_seats || 0) - (members || 0)
  }, [space])

  // ? Does this need a refactoring to group all the return values?
  return {
    userRecap,
    spaceRecap,
    isPaidPlan,
    integrations,
    // Limits
    /** Usage recap */
    usage,
    /** Check if the user has reached the limit for a given resource. */
    isAtQuota,
    /** Check if the user has exceeded the limit for a given resource. */
    isOverQuota,
    /** Check if the user has exceeded the limit for any resource. */
    isOverReached,
    isProExpired,
    /** Available member seats for the current space */
    availableMemberSeats,
    /** Features that are enabled for the current space. */
    features,
    /** User type (admin, teacher, student, editor) */
    userType,
    /** Upgrade link for the current space */
    upgradeLink
  }
}

/*
 * ===============================================================================
 * Helper functions
 * ===============================================================================
 */

function getPlanUpgradeLink(space: Space, isMinor: boolean, plans: Subscriptions["plans"]) {
  const userType = getUserType(space.roles)

  switch (space.space) {
    case "pro":
      if (userType === "admin" && !plans.some(item => Object.keys(SPACE_PAID_PLANS).includes(item))) {
        return `${import.meta.env.VITE_STORE}/enterprise/purchase/plan?frequency=yearly`
      }
      break
    case "edu":
      // Edu can always upgrade (i.e. get more seats)
      if (userType === "admin") {
        return `${import.meta.env.VITE_STORE}/education/purchase/institution`
      }
      break
    default:
      if (!isMinor && !plans.includes("create-maker-plus")) {
        return `${import.meta.env.VITE_STORE}/purchase/plan?plan=makerplus&frequency=yearly`
      }
      break
  }

  return undefined
}
