import type { KitListItem } from "@/types"

const isDev = import.meta.env.VITE_HF_CDN_ENV === "dev"

export const kits: Record<string, Omit<KitListItem, "id">> = {
  ENGINEERING: {
    title: "Engineering Kit",
    greatForSchool: true,
    age: "Intermediate (age 17+)",
    freeLessons: 0,
    description:
      "Develop key engineering skills and learn core aspects of mechatronics and Matlab & Simulink programming.",
    url: isDev ? "https://aek-dev.arduino.cc/" : "https://aek.arduino.cc/",
    education: "https://www.arduino.cc/education/engineering-kit",
    preview: "https://edu-content-preview.arduino.cc/content-preview/university/project/CONTENTPREVIEW+AEKR2",
    minor: false,
    image: {
      url: "/images/kits/ENGINEERING.png"
    },
    isFree: true
  },

  IOTSK2: {
    title: "Explore IoT Rev2",
    greatForSchool: true,
    age: "Intermediate (age 16+)",
    freeLessons: 2,
    description:
      "We will learn how to harness the power of the IoT Cloud to collect data, gain knowledge about communication protocols, advanced technology made simple!",
    url: isDev ? "https://courses.oniudra.cc/explore-iot/" : "https://courses.arduino.cc/explore-iot/",
    education: "https://courses.arduino.cc/explore-iot/home/",
    preview: isDev ? "https://courses.oniudra.cc/explore-iot/" : "https://courses.arduino.cc/explore-iot/",
    minor: false,
    isFree: true,
    image: {
      url: "/images/kits/IOTSK2.png"
    }
  },

  PHYSICSLAB: {
    title: "Science Kit Physics Lab",
    greatForSchool: true,
    age: "Beginner (age 11+)",
    freeLessons: 0,
    description:
      "Empower students to think and act like real scientists by bringing an inquiry-based, hands-on approach to physics into the classroom.",
    url: isDev ? "https://physics-lab.oniudra.cc/" : "https://physics-lab.arduino.cc/",
    education: "https://www.arduino.cc/education/science-kit",
    preview: "https://physics-lab.arduino.cc/activities/the-pirate-ship",
    minor: true,
    isFree: true,
    image: {
      url: "/images/kits/PHYSICSLAB.png"
    }
  },

  EXPLORE_PHYSICS: {
    title: "Explore Physics",
    greatForSchool: true,
    age: "Beginner (age 13+)",
    freeLessons: 0,
    description:
      "Enhanced understanding of physics: No more passive reading. Dive deep into physics, understanding complex concepts through hands-on experimentation.",
    url: isDev ? "https://courses.oniudra.cc/explore-physics/" : "https://courses.arduino.cc/explore-physics/",
    education: "https://www.arduino.cc/education/science-kit-r3/",
    preview: "",
    minor: true,
    image: {
      url: "/images/kits/EXPLORE_PHYSICS.png"
    },
    isFree: true
  },

  CTC101: {
    title: "CTC 101",
    greatForSchool: false,
    age: "Intermediate (age 14+)",
    freeLessons: 0,
    description:
      "CTC 101 (Creative Technologies in the Classroom) is a STEAM program developed to introduce foundations of programming, electronics, and mechanics through a series of playful, well-documented projects and easy-to-assemble experiments.",
    url: isDev ? "https://ctc101-dev.arduino.cc/" : "https://ctc101.arduino.cc/",
    education: "",
    preview: "",
    minor: false,
    isFree: true,
    image: {
      url: "/images/kits/CTC101.png"
    }
  },

  OPLA: {
    title: "Oplà IoT Kit",
    greatForSchool: false,
    age: "Intermediate (age 17+)",
    freeLessons: 0,
    description:
      "The Arduino Oplà kit is your gateway into the digital world of connected objects and people. Get started with the internet of Things fundamental concepts in an easy way.",
    url: isDev ? "https://opla-dev.arduino.cc/" : "https://opla.arduino.cc/",
    preview: "",
    education: "",
    minor: false,
    isFree: true,
    image: {
      url: "/images/kits/OPLA.png"
    }
  },

  SJ: {
    title: "Science Journal",
    description:
      "Shape your students future: Reason with data, think like a real scientist! A fun science classroom in your pocket.",
    greatForSchool: false,
    age: "Intermediate (age 10+)",
    freeLessons: 0,
    url: isDev ? "https://science-journal-dev.arduino.cc" : "https://science-journal.arduino.cc/",
    minor: false,
    isFree: true,
    preview: "",
    education: "https://www.arduino.cc/education/science-journal",
    image: {
      url: "/images/kits/SJ.png"
    }
  },

  PlUG_AND_MAKE: {
    title: "Plug and Make",
    description: "Learn more about Arduino Plug and Make Kit, a modular platform for learning Arduino.",
    greatForSchool: false,
    age: "Intermediate (age 14+)",
    freeLessons: 0,
    url: isDev ? "https://courses.oniudra.cc/plugandmake/" : "https://courses.arduino.cc/plugandmake/",
    minor: false,
    isFree: true,
    preview: "",
    education: "",
    image: {
      url: "/images/kits/PlUG_AND_MAKE.png"
    }
  },

  EXPLORE_PLC: {
    title: "Explore PLC",
    greatForSchool: false,
    age: "Intermediate (age 17+)",
    freeLessons: 0,
    description:
      "A course to approach for the first time, the world of programmable logic controllers, computers for industrial process.",
    url: isDev ? "https://courses.oniudra.cc/explore-plc/" : "https://courses.arduino.cc/explore-plc/",
    education: "https://www.arduino.cc/education/plc-starter-kit ",
    preview: "",
    minor: false,
    image: {
      url: "/images/kits/EXPLORE_PLC.png"
    },
    isFree: true
  },

  BLOCK_BASED: {
    title: "Block Based Coding and Robotics",
    greatForSchool: false,
    age: "Beginner (age 7+)",
    freeLessons: 0,
    description:
      "Introduce younger students to program a robot, while exploring fun STEAM activities that mimic real-life behaviors!",
    url: isDev
      ? "https://courses.oniudra.cc/block-based-coding-robotics/"
      : "https://courses.arduino.cc/block-based-coding-robotics/",
    education: "https://www.arduino.cc/education/arduino-alvik/",
    preview: "",
    minor: false,
    image: {
      url: "/images/kits/BLOCK_BASED.png"
    },
    isFree: true
  },

  EXPLORE_ROBOTICS: {
    title: "Explore Robotics in MicroPython",
    greatForSchool: false,
    age: "Intermediate (age 12+)",
    freeLessons: 0,
    description: "Program and interact with your Alvik while building real skills along the way.",
    url: isDev
      ? "https://courses.oniudra.cc/explore-robotics-micropython/"
      : "https://courses.arduino.cc/explore-robotics-micropython/",
    education: "https://www.arduino.cc/education/arduino-alvik/",
    preview: "",
    minor: false,
    image: {
      url: "/images/kits/EXPLORE_ROBOTICS.png"
    },
    isFree: true
  },

  AESK: {
    title: "Education Starter Kit",
    greatForSchool: false,
    age: "Beginner (age 11+)",
    freeLessons: 0,
    description:
      "Get started with programming and electronics with a set of step-by-step exercises - no previous experience necessary!",
    url: isDev ? "https://aesk-contents-dev.arduino.cc/" : "https://aesk.arduino.cc/",
    education: "https://www.arduino.cc/education/edu-starter-kit",
    preview: "https://edu-content-preview.arduino.cc/content-preview/middle_school/lesson/CONTENTPREVIEW+AESK",
    minor: true,
    image: {
      url: "/images/kits/AESK.png"
    }
  },

  BRACCIO: {
    title: "Braccio ++",
    greatForSchool: false,
    age: "Intermediate (age 16+)",
    freeLessons: 0,
    description:
      "Robotic arm designed expressly for higher education, engineering schools and university institutes of technology.",
    url: isDev ? "https://braccio-dev.arduino.cc/" : "https://braccio.arduino.cc/",
    education: "https://www.arduino.cc/education/braccio",
    preview: "https://edu-content-preview.arduino.cc/content-preview/university/project/CONTENTPREVIEW+BRACCIO",
    minor: false,
    image: {
      url: "/images/kits/BRACCIO.png"
    }
  },

  CTCGO: {
    title: "CTC GO!",
    greatForSchool: false,
    age: "Intermediate (age 14+)",
    freeLessons: 0,
    description:
      "Progress from the basics of electricity and coding to advanced circuit building and programming through a set of hands-on experiments.",
    url: isDev ? "https://ctc-go-contents-dev.arduino.cc/" : "https://ctc-go.arduino.cc/",
    education: "https://www.arduino.cc/education/ctc-go",
    preview: "https://edu-content-preview.arduino.cc/content-preview/high_school/project/CONTENTPREVIEW+CTCGO",
    minor: false,
    image: {
      url: "/images/kits/CTCGO.png"
    }
  },

  CTCGOMOTION: {
    title: "CTC GO! Motions",
    greatForSchool: false,
    age: "Intermediate (age 14+)",
    freeLessons: 0,
    description:
      "Motions is an expansion for CTC GO!, and builds further on the concepts introduced in the Core Module. This kit is focused on movement and mobility, allowing students to learn how to use different servo motors, gears and pulleys to create a set of fun tabletop inventions through 4 lessons and 4 guided projects.",
    url: isDev ? "https://ctc-go-contents-dev.arduino.cc/" : "https://ctc-go.arduino.cc/",
    education: "https://www.arduino.cc/education/ctc-go",
    preview: "https://edu-content-preview.arduino.cc/content-preview/high_school/project/CONTENTPREVIEW+CTCGOMOTION",
    minor: false,
    extensionOf: "CTCGO",
    image: {
      url: "/images/kits/CTCGO.png"
    }
  },

  AEKR2: {
    title: "Engineering Kit Rev2",
    greatForSchool: true,
    age: "Intermediate (age 17+)",
    freeLessons: 0,
    description:
      "Develop key engineering skills and learn core aspects of mechatronics and Matlab & Simulink programming.",
    url: isDev ? "https://engineeringkit-dev.arduino.cc/" : "https://engineeringkit.arduino.cc/",
    education: "https://www.arduino.cc/education/engineering-kit",
    preview: "https://edu-content-preview.arduino.cc/content-preview/university/project/CONTENTPREVIEW+AEKR2",
    minor: false,
    image: {
      url: "/images/kits/AEKR2.png"
    }
  },

  IOTSK: {
    title: "Explore IoT Kit",
    greatForSchool: true,
    age: "Intermediate (age 16+)",
    freeLessons: 0,
    description:
      "Get started with the Internet of Things! Learn how to collect, store, and visualize data, and build remotely controlled smart devices.",
    url: isDev ? "https://explore-iot-dev.arduino.cc/" : "https://explore-iot.arduino.cc/",
    education: "",
    preview: "https://edu-content-preview.arduino.cc/content-preview/high_school/lesson/CONTENTPREVIEW+IOTSK",
    minor: false,
    image: {
      url: "/images/kits/IOTSK.png"
    }
  },

  CTC101SELF: {
    title: "CTC 101 (self)",
    greatForSchool: false,
    age: "Intermediate (age 16+)",
    freeLessons: 0,
    description:
      "CTC 101 (Creative Technologies in the Classroom) is a STEAM program developed to introduce foundations of programming, electronics, and mechanics through a series of playful, well-documented projects and easy-to-assemble experiments.",
    url: isDev ? "https://ctc101-dev.arduino.cc/" : "https://ctc101.arduino.cc/",
    education: "",
    preview: "",
    minor: false,
    isFree: false,
    image: {
      url: "/images/kits/CTC101.png"
    },
    isLegacy: true
  },

  GREENHOUSE: {
    title: "Greenhouse Kit",
    description: "Learn how to use innovative technology to boost sustainability by creating an automated greenhouse.",
    greatForSchool: false,
    age: "Intermediate (age 16+)",
    freeLessons: 0,
    url: isDev ? "https://greenhouse-kit.oniudra.cc" : "https://greenhouse-kit.arduino.cc",
    minor: false,
    isFree: false,
    preview: "https://edu-content-preview.arduino.cc/content-preview/high_school/lesson/CONTENTPREVIEW+GREENHOUSE",
    education: "https://www.campustore.it/arduino-greenhouse-kit-miniserra-e-lezioni-incluse.html",
    image: {
      url: "/images/kits/GREENHOUSE.png"
    }
  },

  STUDENTKIT: {
    title: "Student Kit",
    greatForSchool: false,
    age: "Beginner (age 11+)",
    freeLessons: 0,
    description:
      "Learn electronics and get started with programming with this beginner-friendly kit, ideal for self-learning.",
    url: isDev ? "https://studentkit-dev.arduino.cc/" : "https://studentkit.arduino.cc/",
    education: "https://www.arduino.cc/education/student-kit",
    preview: "https://edu-content-preview.arduino.cc/content-preview/middle_school/lesson/CONTENTPREVIEW+STUDENTKIT",
    minor: true,
    image: {
      url: "/images/kits/STUDENTKIT.png"
    }
  }
}
