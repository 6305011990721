import { snackbar } from "@bcmi-labs/art-ui/components"
import { useCloudContext } from "@bcmi-labs/cloud-sidebar"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useCallback, useSyncExternalStore } from "react"

import { updateSpace } from "@/api/classroom"
import { query } from "@/api/query"
import { parseError } from "@/api/utils/NetworkError"
import { MY_SPACE } from "@/constants/spaces"
import { type Space } from "@/types"

/**
 * A hook that returns the selected space from the store as a stateful value.
 *
 * Using this will subscribe you to the spaces related queries and mutations; if you don't need this, use `useCloudContext` instead.
 *  */
export function useSpace() {
  const { storage } = useCloudContext()
  const spaceId = useSyncExternalStore(
    cb => {
      storage.subscribe(cb)
      return () => storage.unsubscribe(cb)
    },
    () => storage.getSpace(),
    () => MY_SPACE.id
  )

  // Return an empty array when undefined, to avoid errors when mapping over the space list.
  /**
   * Get the list of spaces for the current user
   */
  const spaceList = useQuery({
    ...query.space.list,
    staleTime: Infinity
  })

  /**
   * Get the selected space
   */
  const space = spaceList.data?.find(sp => sp.id === spaceId) || (MY_SPACE as Space)

  /** Set the current space  (updates the cookie via the storage context) */
  const setSpace = useCallback((id: string) => storage.setSpace(id), [storage])

  /*
   * ====================
   * Space Actions
   * - These mutations are triggered by user actions on the currently selected space.
   * ====================
   */

  const updateSpaceMutation = useMutation({
    mutationFn: (payload: Parameters<typeof updateSpace>[0]) => updateSpace(payload, spaceId),
    onSuccess: () => {
      spaceList.refetch()
      snackbar({
        message: "Space information updated",
        variant: "success"
      })
    },
    onError(error) {
      snackbar({
        message: `Network Error: ${parseError(error).body.detail}`,
        variant: "success"
      })
    }
  })

  return {
    spaceList,
    space,
    setSpace,
    updateSpaceMutation
  }
}
